.background-image-container {
  background-image: url("../../images/tree_bg.png"); /* Replace with the path to your background image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding-bottom:40px;
  align-items: center;
  color: white; /* Set text color to white for better visibility on the background */
}

.heading {
  color: #fff;
  font-family: Poppins;
  font-size: 40px;
  height: 5px;
  margin-top: 40px;
}

.dics {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 180% */
}

.subHeading {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* index.css */

.split-screen-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.split-screen-panel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

/* Style for the left panel */
.split-screen-panel:nth-child(odd) {
  background-color: #ccc;
}

/* Style for the right panel */
.split-screen-panel:nth-child(even) {
  background-color: #ddd;
}

/* Image style */
.split-screen-panel img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

/* Text style */
.split-screen-panel p {
  font-size: 1.5rem;
  text-align: center;
}

