.txtHeading {
  color: #1a1a1a;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.heading {
  text-align: center;
}

.subMainHeading {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px; /* 180% */
}
.subMainHeading2 {
    color: #434343;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 180% */
}

.mainContainer {
  display: flex;
  width: 100%;
  margin-top: 70px;
  justify-content: center;
}

.mainContainer2 {
    display: flex;
    width: 100%;
    align-items: center;
  }

.image-mainContainer2 {
  width: 70%;
  padding-left: 5vh;
}

.image-mainContainer {
  width: 50%;
  display: flex;
  height: 60vh;
  justify-content: flex-end;
  margin-right: 50px;
}

.image-mainContainer3{
    width: 50%;
    display: flex;
    height: 70vh;
    justify-content: flex-end;
    margin-right: 50px;
  }

.image-mainContainer img {
  width: 100%;
  height: auto;
}

.text-mainContainer {
  width: 30%;
  padding: 20px; /* Add padding or adjust as needed */
  box-sizing: border-box; /* Include padding in the width */
}

.lowersubMainHeading {
  color: #434343;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 170.833% */
}

html {
  height: 100%;
}

body {
  margin: 0;
}

.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #eca400 50%, white 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

.content {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.25em;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  left: 50%;
  padding: 10vmin;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

h1 {
  font-family: monospace;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
