.pollution-map-container {
  background-color: #2b2b2b;
  color: white; /* Set text color to white for better visibility on a black background */
  display: flex;
  flex-direction: column;
  position: relative; /* Add relative positioning to the container */
  padding-bottom: 50px;
  padding-top: 30px;
}

.heading {
  text-align: center;
}

.subTop {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 60px;
}

.subTop2 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.12px;
  text-transform: capitalize;
}

.img {
  width: 50%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-right: 50px;
}

.img2 {
    width: 50%;
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-right: 50px;
  }