.India-Map {
  background-color: #2b2b2b;
  color: white; /* Set text color to white for better visibility on a black background */
  display: flex;
  flex-direction: column;
  position: relative; /* Add relative positioning to the container */
  padding-bottom: 100px;
  height: 100vh;
}

.heading {
  text-align: center;
}

.mapDisc {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 180% */
  max-width: 50%;
  margin-top: 80px;
}

.images-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 50px;
  margin-right: 50px;
}

.images-container img {
  width: 70%; /* Set a common width for all images */
  height: auto; /* Maintain the aspect ratio */
}
