.pollution-map-container {
  background-color: #2b2b2b;
  color: white; /* Set text color to white for better visibility on a black background */
  display: flex;
  flex-direction: column;
  position: relative; /* Add relative positioning to the container */
  padding-bottom: 50px;
  padding-top: 30px;
}

.box {
  border-radius: 10;
  width: 16%;
  height: 120px;
  border-radius: 10px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}

.topHead {
  color: #fff;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.topSub {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}

.boxHeading {
  color: #fefefe;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  margin: 0;
}

.boxPara {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  margin: 0;
}

.heading {
  text-align: center;
}

.subHeading {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 180% */
}

.img {
  width: 50%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-right: 50px;
}

.headingMid {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 166.667% */
}
