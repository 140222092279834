.index {
    background-color: #292929;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .index .group-wrapper {
    background-color: #292929;
    border: 1px none;
    height: 1750px;
    width: 1920px;
  }
  
  .index .group {
    height: 1932px;
    position: relative;
    width: 1648px;
  }
  
  .index .overlap {
    height: 1932px;
    position: relative;
  }
  
  .index .overlap-group {
    height: 834px;
    left: 232px;
    position: absolute;
    top: -14%;
    width: 1123px;
  }
  
  .index .div {
    border-radius: 20px;
    height: 542px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1123px;
  }
  
  .index .overlap-group-wrapper {
    border-radius: 20px;
    height: 234px;
    left: 0;
    position: absolute;
    top: 308px;
    width: 612px;
  }
  
  .index .overlap-group-2 {
    background-color: #191919;
    border-radius: 20px;
    height: 234px;
    position: relative;
    width: 608px;
  }
  
  .index .span-wrapper {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 22px;
    font-weight: 500;
    left: 24px;
    letter-spacing: 0.88px;
    line-height: normal;
    position: absolute;
    top: 34px;
    width: 560px;
  }
  
  .index .text-wrapper {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
  }
  
  .index .p {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 24px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 82px;
    width: 560px;
  }
  
  .index .span {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .index .timeline {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 600;
    left: 609px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -1px;
  }
  
  .index .text-wrapper-2 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .index .lorem-ipsum-dolor {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 285px;
    letter-spacing: 0;
    line-height: 36px;
    position: absolute;
    text-align: center;
    top: 87px;
    width: 834px;
  }
  
  .index .text-wrapper-3 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36px;
  }
  
  .index .element {
    height: 42px;
    left: 0;
    position: absolute;
    top: 256px;
    width: 58px;
  }
  
  .index .span-wrapper-2 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 28px;
    font-weight: 600;
    left: 0;
    letter-spacing: 1.12px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 15px;
  }
  
  .index .text-wrapper-4 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 28px;
    font-weight: 600;
    line-height: normal;
  }
  
  .index .group-2 {
    height: 286px;
    left: 404px;
    position: absolute;
    top: 548px;
    width: 608px;
  }
  
  .index .div-wrapper {
    border-radius: 20px;
    height: 234px;
    left: 0;
    position: absolute;
    top: 52px;
    width: 608px;
  }
  
  .index .group-3 {
    border-radius: 20px;
    height: 234px;
    width: 612px;
  }
  
  .index .text-wrapper-5 {
    font-weight: 400;
  }
  
  .index .october {
    height: 42px;
    left: 413px;
    position: absolute;
    top: 0;
    width: 195px;
  }
  
  .index .vector {
    height: 181px;
    left: 608px;
    position: absolute;
    top: 419px;
    width: 50px;
  }
  
  .index .overlap-2 {
    height: 376px;
    left: 636px;
    position: absolute;
    top: 765px;
    width: 608px;
  }
  
  .index .group-4 {
    height: 245px;
    left: 0;
    position: absolute;
    top: 131px;
    width: 608px;
  }
  
  .index .group-5 {
    border-radius: 20px;
    height: 193px;
    left: 0;
    position: absolute;
    top: 52px;
    width: 608px;
  }
  
  .index .group-6 {
    border-radius: 20px;
    height: 193px;
    width: 612px;
  }
  
  .index .overlap-group-3 {
    background-color: #191919;
    border-radius: 20px;
    height: 193px;
    position: relative;
    width: 608px;
  }
  
  .index .launched-under-the {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 24px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 79px;
    width: 560px;
  }
  
  .index .april {
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 146px;
  }
  
  .index .img {
    height: 181px;
    left: 354px;
    position: absolute;
    top: 0;
    width: 50px;
  }
  
  .index .overlap-3 {
    height: 640px;
    left: 0;
    position: absolute;
    top: 1045px;
    width: 1244px;
  }
  
  .index .group-7 {
    height: 218px;
    left: 0;
    position: absolute;
    top: 132px;
    width: 840px;
  }
  
  .index .group-8 {
    border-radius: 20px;
    height: 166px;
    left: 232px;
    position: relative;
    top: 52px;
    width: 608px;
  }
  
  .index .group-9 {
    border-radius: 20px;
    height: 166px;
    width: 612px;
  }
  
  .index .overlap-group-4 {
    background-color: #191919;
    border-radius: 20px;
    height: 166px;
    position: relative;
    width: 608px;
  }
  
  .index .the-scheme-was {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 18px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 78px;
    width: 560px;
  }
  
  .index .group-10 {
    height: 216px;
    left: 636px;
    position: absolute;
    top: 424px;
    width: 608px;
  }
  
  .index .group-11 {
    border-radius: 20px;
    height: 164px;
    left: 0;
    position: absolute;
    top: 52px;
    width: 608px;
  }
  
  .index .group-12 {
    border-radius: 20px;
    height: 164px;
    width: 612px;
  }
  
  .index .overlap-group-5 {
    background-color: #191919;
    border-radius: 20px;
    height: 164px;
    position: relative;
    width: 608px;
  }
  
  .index .guidelines-and {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 79px;
    width: 560px;
  }
  
  .index .december {
    height: 42px;
    left: 377px;
    position: absolute;
    top: 0;
    width: 231px;
  }
  
  .index .vector-2 {
    height: 181px;
    left: 840px;
    position: absolute;
    top: 295px;
    width: 51px;
  }
  
  .index .vector-3 {
    height: 181px;
    left: 576px;
    position: absolute;
    top: 0;
    width: 60px;
  }
  
  .index .overlap-4 {
    height: 415px;
    left: 1040px;
    position: absolute;
    top: 445px;
    width: 608px;
  }
  
  .index .group-13 {
    height: 286px;
    left: 0;
    position: absolute;
    top: 129px;
    width: 608px;
  }
  
  .index .the-roadmap {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 19px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 108px;
    width: 560px;
  }
  
  .index .january {
    height: 42px;
    left: 408px;
    position: absolute;
    top: 0;
    width: 200px;
  }
  
  .index .vector-4 {
    height: 179px;
    left: 204px;
    position: absolute;
    top: 0;
    width: 60px;
  }
  