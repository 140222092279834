.headingTop {
  color: #1a1a1a;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 50px;
}

.subHeadingTop {
    color: #434343;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 180% */
    text-transform: capitalize;
}

.dropDown{
    width: 150px;
    height:40px;
    margin-left: 100px;
    margin-top: 20px;
}

.image-containerTop {
    display: flex;
    height: 60vh;
    width: '50%';
    margin-top: 40px;
   padding-left: 50px;
   padding-right: 50px;
  }
  
  .image {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  