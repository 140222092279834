.pollution-map-container {
  background-color: #2b2b2b;
  color: white; /* Set text color to white for better visibility on a black background */
  display: flex;
  flex-direction: column;
  position: relative; /* Add relative positioning to the container */
  padding-bottom: 50px;
  padding-top: 30px;
}

.heading {
  text-align: center;
}

.subHeading {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px; /* 180% */
}
.subHeading2 {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px; /* 180% */
  }

.container {
    display: flex;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  .image-container2 {
    width: 70%;
  }

  .image-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
  }
  
  .text-container {
    /* width: 50%; */
    padding: 20px; /* Add padding or adjust as needed */
    box-sizing: border-box; /* Include padding in the width */
  }


  
