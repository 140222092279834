/* Add this style to your existing CSS file */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.app {
  text-align: center;
  background-image: url('./images/tree_bg.png');
  padding-bottom: 50px;
}

.top-heading{
  margin-top: 10px;
  color: #fff;
}

.navbar {
  background-color: transparent;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: flex-end;
}

.navbar-buttons {
  display: flex;
  margin-right: 20px;
}

p {
  margin-left: 10px;
  padding: 8px 15px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.bottonHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
}

.column-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.india-map-container {
  position: relative;
  width: 100%; /* Adjust the width as needed */
  height: 100%; /* Adjust the height as needed */
}

